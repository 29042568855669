.not-found {
  font-size: 46px;
  margin-top: 300px;
  display: flex;
  justify-content: center;
  align-items: center;
  letter-spacing: 1px;
}

.warning {
  color: var(--coinColor);
  margin-right: 10px;
}

.not-found-button {
  margin-top: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
}
