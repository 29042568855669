* {
  box-sizing: border-box;
  margin: 0;
}

.containerFather {
  min-height: 100vh;
  font-family: "Montserrat", sans-serif;
  display: flex;
  justify-content: center;
  align-items: center;
}

.eye-registration button:focus {
  border: 0;
  outline: none;
}

.containerMy {
  width: 700px;
  height: 520px;
  border-radius: 20px;
  overflow: hidden;
  display: flex;
}

.container__svgs {
  width: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.container__svg1 {
  width: 200px;
  height: 200px;
  margin-bottom: 10px;
}

.container__svg2 {
  width: 170px;
  height: 18px;
}

.container__content {
  width: 50%;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.container__form {
  margin-bottom: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.container__inputField {
  background-color: transparent;
  width: 220px;
  padding: 0 0 8px 4px;
  font-size: 15px;
  letter-spacing: 1px;
  border: none;
  border-bottom: solid 1px var(--color2);
  border-radius: 0;
}

.container__inputField:first-of-type {
  margin-bottom: 35px;
}

.container__inputField:nth-of-type(2) {
  margin-bottom: 40px;
}

.container__inputField:focus-visible {
  border-bottom: solid 2px var(--color4Hover);
  outline: none;
}

.container__links {
  position: absolute;
  left: 50%;
  bottom: 30px;
  transform: translateX(-50%);
  display: flex;
  align-items: center;
  justify-content: center;
  width: 260px;
}

.container__link {
  font-size: 14px;
  text-decoration: none;
}

a.container__link:hover {
  text-decoration: underline;
  cursor: pointer;
}

.container__separator {
  width: 1px;
  height: 10px;
  margin: 0 15px;
}

.text-new-user {
  margin-bottom: 30px;
}

.eyers-registration {
  position: relative;
}

.eye-registration-icon {
  color: var(--color) !important;
}

.eye-registration {
  position: absolute;
  right: 0;
  bottom: 33px;
}

.button-register button {
  font-size: 18px;
  margin-bottom: 20px;
}

@media screen and (max-width: 660px) {
  .container__svgs {
    display: none !important;
  }

  .containerMy {
    display: flex;
    justify-content: center !important;
  }
}

@media screen and (max-width: 420px) {
  .container__content {
    min-width: 300px !important;
  }
}
