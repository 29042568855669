.eyers-newpass {
  position: relative;
}

.eye-newpass {
  position: absolute;
  right: 0;
  bottom: 33px;
}

.eye-newpass button:focus {
  border: 0;
  outline: none;
}
