* {
  box-sizing: border-box;
  margin: 0;
}

.containerFather {
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.eye-login button:focus {
  border: 0;
  outline: none;
}

.containerMy {
  background-color: var(--backgroundColor2);
  width: 700px;
  height: 520px;
  border-radius: 20px;
  border: 2px solid var(--coinColor);
  overflow: hidden;
  display: flex;
}

.container__svgs {
  background-color: var(--backgroundColor2);
  width: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.container__svg1 {
  width: 200px;
  height: 200px;
  margin-bottom: 10px;
}

.container__svg2 {
  width: 170px;
  height: 18px;
}

.container__content {
  width: 50%;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.container__form {
  margin-bottom: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.container__inputField {
  background-color: transparent;
  width: 220px;
  padding: 10px !important;
  letter-spacing: 1px;
  border: none;
  border-bottom: solid 1px var(--coinColor) !important;
  border-radius: 0;
  color: var(--color);
}

.container__inputField:focus-visible {
  border-bottom: solid 2px var(--coinColor) !important;
  outline: none;
  background-color: var(--backgroundColor3);
}

.container__links {
  position: absolute;
  left: 50%;
  bottom: 30px;
  transform: translateX(-50%);
  display: flex;
  align-items: center;
  justify-content: center;
  width: 260px;
}

.link-register {
  color: var(--coinColor) !important;
}

.container__link {
  color: var(--color);
  font-size: 14px;
  text-decoration: none;
}

a.container__link:hover {
  text-decoration: underline;
  cursor: pointer;
}

.container__separator {
  background-color: var(--color);
  width: 1px;
  height: 10px;
  margin: 0 15px;
}

.container__separatorMy {
  background-color: var(--color);
  width: 1px;
  height: 480px;
  margin-top: 20px;
}

.forgotpasswd-login {
  position: absolute;
  color: var(--coinColor);
  font-size: 12px;
  margin-right: 68px;
  margin-top: 54px;
}

a.forgotpasswd-login:hover {
  text-decoration: underline;
  cursor: pointer;
}

.text-new-user {
  margin-top: 20px;
}

.eyers {
  position: relative;
  margin-bottom: 20px;
}

.eye-login {
  position: absolute;
  right: 0;
  bottom: 33px;
}

.eye-login-icon {
  color: var(--color) !important;
}

@media screen and (max-width: 660px) {
  .container__svgs {
    display: none !important;
  }

  .containerMy {
    display: flex;
    justify-content: center !important;
    margin: 40px;
  }

  .container__separatorMy {
    display: none !important;
  }
}

@media screen and (max-width: 460px) {
  .container__content {
    min-width: 300px !important;
  }
}
