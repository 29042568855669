.btn-default {
  background-color: var(--backgroundColor3);
  color: var(--color);
  padding: 6px 12px 6px 12px;
  min-width: 120px;
  /* font-size: 20px; */
  cursor: pointer;
  text-transform: uppercase;
  letter-spacing: 2px;
  border: none;
  border-radius: 50px;
}

.btn-default:hover {
  background-color: var(--backgroundColor);
}

.btn-default:focus {
  outline: none !important;
  border: none !important;
}
