.container-bar-chart {
  display: flex;
  gap: 20px;
}

.bar-chart {
  width: 70%;
  height: 220px;
  border: 1px solid gray;
  border-radius: 20px;
}

.toltip-bar-chart {
  background-color: var(--backgroundColor);
  color: var(--color) !important;
}

.title-categorychart {
  position: absolute;
  margin-left: 360px;
  margin-top: 10px;
}

.bar-chart-details {
  width: 30%;
  height: 220px;
  border: 1px solid gray;
  border-radius: 20px;
  padding: 20px;
}

.bar-chart-details h5 {
  text-align: center;
}

.label-details {
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid var(--backgroundColor);
}

.bar-chart-git {
  margin-top: 20px;
  padding-top: 30px;
  width: 69%;
  height: 280px;
  border: 1px solid gray;
  border-radius: 20px;
}

.title-categorychart-git {
  position: absolute;
  margin-left: 360px;
  margin-top: 30px;
}

@media screen and (max-width: 1400px) {
  .container-entrys-dash {
    margin-left: 70px;
    margin-right: 0px !important;
    min-width: 300px;
  }
  .container-bar-chart,
  .bar-chart-git {
    display: flex;
    flex-direction: column;
    align-items: center !important;
    width: auto;
  }
}

@media screen and (max-width: 990px) {
  .hide-responsive-text {
    display: none !important;
  }
}
