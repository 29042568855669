.container-entrys,
.container-history {
  background-color: var(--backgroundColor2) !important;
  margin-left: 280px;
  margin-right: 80px;
  margin-top: 40px;
  padding: 20px;
  border-radius: 20px;
}

.container-entrys {
  margin-top: 116px;
}

.container-history {
  font-size: 20px;
}

#accordionFlushExample,
#accordionFlushExample:focus,
#accordionFlushExample:active {
  background-color: var(--backgroundColor2) !important;
}

#flush-collapseOne,
#flush-collapseOne:focus,
#flush-collapseOne:active {
  width: 100%;
}

.accordion-button,
.accordion-button:focus,
.accordion-button:active {
  background-color: var(--backgroundColor2) !important;
  font-size: 20px !important;
  color: var(--color) !important;
  outline: none !important;
  box-shadow: none !important;
  border: none;
}

.input-number {
  padding: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 60px;
}

.input-value {
  padding: 20px;
}

.input-value label {
  font-size: 20px;
}

.input-value-children {
  width: 220px;
  height: 48px;
  padding: 10px;
  font-size: 24px;
  border-top: none;
  border-left: none;
  border-right: none;
  border-color: var(--coinColor);
  background-color: var(--backgroundColor2);
  color: var(--color);
}

.input-value-children:active,
.input-value-children:focus {
  outline: none !important;
  border-bottom: 3px inset var(--coinColor);
}

.check-container {
  width: 120px;
}

.check-div input {
  margin-right: 18px;
  transform: scale(1.3);
  cursor: pointer;
}

.check-div input:checked::after {
  width: 15px;
  height: 15px;
  border-radius: 15px;
  top: -2px;
  left: -1px;
  position: relative;
  background-color: var(--coinColor);
  content: "";
  display: inline-block;
  visibility: visible;
  border: 1px solid var(coinColor2);
  box-shadow: 0.6px 0.2px 1px 2.8px rgba(53, 53, 53, 0.2);
}

.check-div input::after {
  width: 15px;
  height: 15px;
  border-radius: 15px;
  top: -2px;
  left: -1px;
  position: relative;
  content: "";
  display: inline-block;
  visibility: visible;
  border: 1px solid var(--color);
}

.check-div label {
  font-size: 20px;
}

.category-list label {
  font-size: 20px;
}

.category-list option,
.category-list select {
  color: var(--color);
}

.category-list select {
  width: 230px;
  padding: 9px;
  border-radius: 4px;
  background-color: var(--backgroundColor);
  cursor: pointer;
}

th,
td {
  text-align: center;
  color: var(--color);
}

.trash {
  border: 0;
  outline: none;
  color: rgb(175, 54, 54);
}

.trash-history button:focus {
  border: 0;
  outline: none;
  background-color: rgb(218, 115, 115);
  color: rgb(175, 54, 54);
}

.trash-history button:hover {
  border: 0;
  outline: none;
  background-color: rgb(218, 115, 115);
  color: rgb(175, 54, 54);
}

#exampleModal {
  width: 100%;
}

.modal-content {
  background-color: var(--backgroundColor2);
}

.modal-backdrop.show {
  width: 100%;
}

.cancel-modal:hover {
  background-color: rgb(219, 76, 76) !important;
}

.confirm-modal:hover {
  background-color: rgb(76, 184, 76) !important;
}

.show-responsive {
  display: none;
}

.home-check {
  width: 115px;
}

@media screen and (max-width: 1400px) {
  .input-number {
    gap: 20px;
  }
}

@media screen and (max-width: 1700px) {
  .input-value-children {
    width: 180px;
  }

  .category-list select {
    width: 170px;
  }
}

@media screen and (max-width: 1290px) {
  /*1554 do what*/
  .input-number {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .hide-responsive {
    display: none;
  }

  .show-responsive {
    display: block;
  }
}

@media screen and (max-width: 840px) {
  .hide-responsive-device {
    display: none;
  }

  .container-entrys,
  .container-history {
    margin-left: 70px;
    margin-right: 0px !important;
    min-width: 300px;
  }

  .accordion-header button {
    z-index: 0;
  }
}
