:root {
  /*defaultvariables*/
  --backgroundColor: rgba(25, 25, 25);
  --backgroundColor2: rgba(32, 32, 32);
  --backgroundColor3: rgb(16, 16, 16);

  /*dark*/
  --backgroundColor9: rgba(25, 25, 25);
  --backgroundColor10: rgba(32, 32, 32);
  --backgroundColor11: rgb(16, 16, 16);

  /*default*/
  --backgroundColor4: #415059;
  --backgroundColor5: #526470;

  /*light*/
  --backgroundColor6: rgba(220, 219, 217);
  --backgroundColor7: rgba(235, 235, 233);
  --backgroundColor8: rgba(255, 255, 255);

  /*colors*/
  --blackAll: black;
  --whiteAll: white;
  --color: #ffffffcf;
  --color2: #7c828d;
  --color3: #e9ecef;
  --color4: rgb(55, 53, 47);
  --color5: #ffffffcf;
  --coinColor: #ffb703;
  --coinColor2: #816317;
  --navWidth: 60px;
}

body {
  background-color: var(--backgroundColor);
  color: var(--color) !important;
  margin: 0;
  font-family: "Montserrat", -apple-system, BlinkMacSystemFont, "Segoe UI",
    "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.disabled {
  color: #adadad !important;
}
