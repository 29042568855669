.cards-missions {
  display: grid;
  grid-template-columns: 33% 33% 33%;
  justify-content: center;
  gap: 26px;
  padding: 20px;
}

.card-mission {
  background-color: var(--backgroundColor);
  padding: 10px;
  border: 2px solid var(--backgroundColor);
  border-radius: 20px;
}

.title-mission {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid var(--color);
}

.coin-mission {
  color: var(--coinColor);
  font-size: 18px;
}

.icon-mission {
  width: 60px;
  height: 60px;
  border-radius: 50px;
  margin-bottom: 2px;
}

@media screen and (max-width: 1600px) {
  .cards-missions {
    margin-left: 70px;
    margin-right: 0px !important;
    min-width: 300px;
  }
}

@media screen and (max-width: 990px) {
  .hide-responsive-text {
    display: none !important;
  }

  .cards-missions {
    margin-left: 70px;
    margin-right: 0px !important;
    min-width: 300px;
    display: flex;
    flex-direction: column;
    align-items: center !important;
  }
}

@media screen and (max-width: 500px) {
  .hide-responsive-text {
    display: none !important;
  }

  .cards-missions {
    min-width: 300px;
    margin: 0 auto;
  }
}
