.navbar {
  background-color: var(--backgroundColor2);
  height: 90px;
}

.balance-value {
  color: var(--coinColor);
}

.nav-notify {
  font-size: 24px;
}

.dropdown-menu {
  width: 220px !important;
}

.buy-coffee {
  font-size: 26px;
  margin-bottom: 8px;
}

.theme-brush {
  font-size: 26px;
}

.buy-coffee a,
.theme-brush a {
  color: var(--color);
}

.navbar-header {
  display: flex;
  gap: 20px;
  align-items: center;
}

.navbar-nav {
  display: flex;
  gap: 40px;
  align-items: center;
}

.balance-container {
  max-height: 58px;
}

.eye-value-icon {
  color: var(--color) !important;
}

.eye-value button:focus {
  border: 0;
  outline: none;
}

.dropdown-item {
  white-space: break-spaces;
}

.not-selectable {
  user-select: none;
}

#header-toggle {
  cursor: pointer;
}

.logo-font-nav {
  font-family: "Agbalumo";
  font-size: 40px;
}

.logo-font-nav-mini {
  font-family: "Agbalumo";
  font-size: 18px;
}

@media screen and (max-width: 991px) {
  .navbar-nav {
    flex-direction: row;
  }

  .hide-responsive-device {
    display: none;
  }

  .category-list {
    text-align: center;
  }
}

a {
  text-decoration: none;
}
a:hover {
  color: var(--coinColor);
}
.header_toggle {
  font-size: 1.5rem;
  cursor: pointer;
}
.side-navbar {
  position: fixed;
  top: 0;
  left: -30%;
  width: var(--navWidth);
  height: 100vh;
  background-color: var(--backgroundColor2);
  color: var(--color);
  padding: 0.5rem 1rem 0 0;
  transition: 0.5s;
  z-index: 1;
  margin-top: 80px;
}
.nav {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  overflow: hidden;
}
.nav_logo,
.nav_link {
  display: grid;
  grid-template-columns: max-content max-content;
  align-items: center;
  column-gap: 1rem;
  padding: 0.5rem 0 0.5rem 1.5rem;
  cursor: pointer;
}
.nav_logo {
  margin-bottom: 2rem;
}
.nav_logo-icon {
  font-size: 1.25rem;
}
.nav_logo-name {
  font-weight: 700;
}
.nav_link {
  position: relative;
  color: var(--color);
  margin-bottom: 1.5rem;
  transition: 0.3s;
}
.nav_icon {
  font-size: 1.25rem;
}
.show {
  left: 0;
}
.body-pd {
  padding-left: calc(var(--navWidth) + 1rem);
}
.active::before {
  content: "";
  position: absolute;
  left: 0;
  width: 2px;
  height: 32px;
}
.height-100 {
  height: 100vh;
}
.perfil {
  text-align: center;
  margin-bottom: 20px;
}
.perfil-photo {
  border-radius: 50%;
  border: 3px solid var(--backgroundColor);
  width: 80px;
  height: 80px;
  display: block;
  margin: 0 auto;
  margin-bottom: 16px;
}
.perfil-name {
  font-size: 22px;
}
.invisible-name {
  visibility: hidden;
}
.moon-coin {
  display: flex;
  gap: 12px;
  justify-content: center;
  align-items: center;
  color: var(--coinColor);
  font-size: 20px;
}
.moon-coin-photo {
  width: 20px;
}
.tooltip .tooltip-inner {
  width: 150px !important;
}
/* @media screen and (min-width: 770px) { */
.body {
  padding-left: calc(var(--navWidth));
}
.side-navbar {
  left: 0;
  padding: 1rem 1rem 0 0;
}
#body-pd .show {
  width: calc(var(--navWidth) + 156px);
}
/* } */
