.config-avatar,
.config-avatar:focus,
.config-avatar:active {
  background-color: transparent;
  color: var(--color);
}

.config-page hr {
  border: 2px solid var(--color);
}

.config-photo {
  width: 140px;
  height: 140px;
}

.cleanData {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
}

.infoAccount {
  color: var(--coinColor);
}

@media screen and (max-width: 1290px) {
  .cleanData {
    flex-direction: column;
  }
}
